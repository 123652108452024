import React from 'react'
import starIcon from '../../assets/images/star-icon.png'
import howItWork from '../../assets/images/how-its-work.png'

const WhyChooseUs = () => {
    return (
        <section className="how-its-work-area ptb-100">
            <div className="container">
                <div className="row align-items-center">
                    <div className="col-lg-6 col-md-12">
                        <div className="how-its-work-content">
                            <span className="sub-title">
                                <img src={starIcon} alt="banner" /> 
                                People Love Us
                            </span>
                            <h2>Why Choose Us?</h2>
                            <p>Our team of business consultants, architects, experts & project managers are ready to discuss your project with you & drive innovation at speed.</p>
                            <div className="inner-box">
                                <div className="single-item">
                                    <div className="count-box">
                                        1
                                    </div>
                                    <h3>Analytics Business</h3>
                                    <p>We enable our clients businesses to embed crucial insights into their processes via a faster time-to-value analytics journey.</p>
                                 </div>
                                <div className="single-item">
                                    <div className="count-box">
                                        2
                                    </div>
                                    <h3>ERP Implementation</h3>
                                    <p>Decades of experience in Implementing, upgrading & migrating ERP solutions both from a functional & technical perspectives.</p>
                                </div>
                                
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-6 col-md-12">
                        <div className="how-its-work-image">
                            <img src={howItWork} alt="banner" />
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default WhyChooseUs