import React from 'react'
import { Link } from 'gatsby'
import aboutImage from '../../assets/images/about/about-img5.png'
import starIcon from '../../assets/images/star-icon.png'
import icon4 from '../../assets/images/icons/icon4.png'
import icon5 from '../../assets/images/icons/icon5.png'
import icon6 from '../../assets/images/icons/icon6.png'
import icon7 from '../../assets/images/icons/icon7.png'
import shape1 from '../../assets/images/shape/circle-shape1.png'
 
const AboutUsContent = () => {
    return (
        <section className="about-area ptb-100">
            <div className="container-fluid">
                <div className="row align-items-center">
                    <div className="col-lg-6 col-md-12">
                        <div className="about-image">
                            <img src={aboutImage} alt="banner" />
                        </div>
                    </div>

                    <div className="col-lg-6 col-md-12">
                        <div className="about-content">
                            <div className="content">
                                <span className="sub-title">
                                    <img src={starIcon} alt="banner" /> 
                                    About Us
                                </span>
                                <h2>Drive Digital Revolution Through Data Science</h2>
                                
                                <ul className="features-list">
                                    <li>
                                        <img src={icon4} alt="banner" />
                                        <h3>10 Years</h3>
                                        <p>On the market</p>
                                    </li>
                                    <li>
                                        <img src={icon5} alt="banner" />
                                        <h3>150+</h3>
                                        <p>Team members</p>
                                    </li>
                                    <li>
                                        <img src={icon6} alt="banner" />
                                        <h3>100%</h3>
                                        <p>Satisfaction rate</p>
                                    </li>
                                    <li>
                                        <img src={icon7} alt="banner" />
                                        <h3>50%</h3>
                                        <p>Onshore/Offshore</p>
                                    </li>
                                </ul>
                                <p>Diamonds Consulting  is a strategic ERP & Analytics consulting company accelerating the complex implementation journey for enterprise and mid-market clients.</p>
                                
                                
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="circle-shape1">
                <img src={shape1} alt="banner" />
            </div>

            <div className="container">
                <div className="about-inner-area">
                    <div className="row">
                        <div className="col-lg-4 col-md-6 col-sm-6">
                            <div className="about-text">
                                <h3>Our Mission</h3>
                                <p>Our Mission is to be our client's trusted advisor & implementation partner. We offer high quality business & technical solutions to enable our clients evolve their business towards a highly competitive & successful enterprise!</p>
                                
                                <ul className="features-list">
                                    <li><i className="flaticon-tick"></i> Creating Results</li>
                                    <li><i className="flaticon-tick"></i> Delivering Value</li>
                                    <li><i className="flaticon-tick"></i> Time-to-Market</li>
                                    <li><i className="flaticon-tick"></i> Cost-Effective</li>
                                </ul>
                            </div>
                        </div>
    
                        <div className="col-lg-4 col-md-6 col-sm-6">
                            <div className="about-text">
                                <h3>Our History</h3>
                                <p>With a very strong ERP Implementation & Data Management / Analytics expertise, Diamonds Consulting has been in the market for more than 10 years helping our clients bring the best out of their ERP and Data Management projects.</p>

                                <ul className="features-list">
                                    <li><i className="flaticon-tick"></i> Inclusive & Diverse</li>
                                    <li><i className="flaticon-tick"></i> Local Canadian Business</li>
                                    <li><i className="flaticon-tick"></i> ERP Implementations</li>
                                    <li><i className="flaticon-tick"></i> Business Analytics</li>
                                </ul>
                            </div>
                        </div>

    
                        <div className="col-lg-4 col-md-6 col-sm-6 offset-lg-0 offset-md-3 offset-sm-3">
                            <div className="about-text">
                                <h3>Who we are</h3>
                                <p>We solve client's data challenges to help optimize marketing campaigns, improve customer experience, increase competitive advantage, and, ultimately, evolve their organizations.</p>
                                
                                <ul className="features-list">
                                    <li><i className="flaticon-tick"></i> Data Experts</li>
                                    <li><i className="flaticon-tick"></i> ERP Experts</li>
                                    <li><i className="flaticon-tick"></i> Onshore/Offshore</li>
                                    <li><i className="flaticon-tick"></i> Trusted Partners</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="circle-shape1">
                <img src={shape1} alt="banner" />
            </div>
        </section>
    )
}

export default AboutUsContent;